var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"store"},[_c('div',{staticClass:"store_bg",style:(("background-image:url(" + (_vm.obj.storePhoto) + ")"))}),_c('div',{staticClass:"store_top"},[_c('van-search',{attrs:{"shape":"round","placeholder":"请输入名称、货号、规格、品牌搜索","clearable":false},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('svg-icon',{attrs:{"icon-class":"home_nav_search_icon"}})]},proxy:true},{key:"left",fn:function(){return [_c('svg-icon',{staticClass:"back",staticStyle:{"margin-right":"10px"},attrs:{"icon-class":"navbar_dcs_back"},on:{"click":_vm.toBack}})]},proxy:true},{key:"right-icon",fn:function(){return [_c('van-button',{attrs:{"round":""},on:{"click":function($event){return _vm.$router.replace(
              ("storeCategory?storeId=" + (_vm.$route.query.storeId) + "&like=" + _vm.search)
            )}}},[_vm._v("搜索")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"store_name"},[_c('img',{attrs:{"src":_vm.obj.storeLogo,"alt":""}}),_c('p',[_vm._v(_vm._s(_vm.obj.storeName))]),_c('div',{staticClass:"store_name_right"})])],1),_c('div',{staticClass:"store_tab"},[_c('a',{class:_vm.$route.name === 'store-home-page' ? 'router-link-active' : '',on:{"click":function($event){return _vm.$router.replace({
          path: 'homePage',
          query: {
            storeId: _vm.$route.query.storeId,
          },
        })}}},[_vm._v("首页")]),_c('a',{class:_vm.$route.name === 'store-category' ? 'router-link-active' : '',on:{"click":function($event){return _vm.$router.replace({
          path: 'storeCategory',
          query: {
            storeId: _vm.$route.query.storeId,
          },
        })}}},[_vm._v("公司类目")])]),_c('keep-alive',[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }