<template>
  <div class="store">
    <div
      class="store_bg"
      :style="`background-image:url(${obj.storePhoto})`"
    ></div>
    <div class="store_top">
      <!-- <img :src="obj.storePhoto" alt=""> -->
      <van-search
        v-model="search"
        shape="round"
        placeholder="请输入名称、货号、规格、品牌搜索"
        :clearable="false"
      >
        <template #left-icon>
          <svg-icon icon-class="home_nav_search_icon" />
        </template>
        <template #left>
          <svg-icon
            icon-class="navbar_dcs_back"
            class="back"
            style="margin-right: 10px"
            @click="toBack"
          />
        </template>
        <template #right-icon>
          <van-button
            round
            @click="
              $router.replace(
                `storeCategory?storeId=${$route.query.storeId}&like=${search}`
              )
            "
            >搜索</van-button
          >
        </template>
      </van-search>
      <div class="store_name">
        <img :src="obj.storeLogo" alt="" />
        <p>{{ obj.storeName }}</p>
        <div class="store_name_right">
          <!-- <a :href="'tel:' + obj.storeServicePhone"><img
              src="../../assets/images/customer_icon.png"
              alt=""
            />联系客服</a> -->
        </div>
      </div>
    </div>

    <div class="store_tab">
      <a
        @click="
          $router.replace({
            path: 'homePage',
            query: {
              storeId: $route.query.storeId,
            },
          })
        "
        :class="$route.name === 'store-home-page' ? 'router-link-active' : ''"
        >首页</a
      >
      <a
        @click="
          $router.replace({
            path: 'storeCategory',
            query: {
              storeId: $route.query.storeId,
            },
          })
        "
        :class="$route.name === 'store-category' ? 'router-link-active' : ''"
        >公司类目</a
      >
      <!-- router-link-active -->
    </div>

    <keep-alive>
      <!-- <transition name="van-slide-left"> -->
      <router-view></router-view>
      <!-- </transition> -->
    </keep-alive>
    
  </div>
</template>

<script>
import { WechatSharing } from "@/utils/sdk";
import { mapState } from "vuex";
export default {
  name: "store",
  computed: {
    ...mapState("user", ["tzInfo"]),
  },
  data() {
    return {
      search: "",
      obj: {},
    };
  },
  watch: {
    $route: {
      handler(val) {
        // console.log(val);
        this.search = val.query.like;
        if (this.$route.query.id) {
          // if (!sessionStorage.getItem("userUuid")) {
          // if (!this.$route.query.id && !sessionStorage.getItem("userUuid")) {
          sessionStorage.setItem("userUuid", this.$route.query.id);
          // }
          // }
        } else if (sessionStorage.getItem("userUuid")) {
          this.$router.replace({
            path: val.path,
            query: {
              storeId: this.$route.query.storeId,
              like: this.$route.query.like ? this.$route.query.like : undefined,
              id: sessionStorage.getItem("userUuid")
                ? sessionStorage.getItem("userUuid")
                : "",
            },
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  created() {
    document.documentElement.scrollTop = 0;
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const data = await this.$API.store.storeHomePage({
        object: {
          like: "",
          location: "",
          order: "",
          sortColumn: "",
          storeId: this.$route.query.storeId,
          userId: this.tzInfo ? this.tzInfo.id : "",
        },
        pageNum: 1,
        pageSize: 1,
      });
      if (data.data.code != "SUCCESS") return false;
      this.obj = data.data.data;

      let shareInfo = {
        title: this.obj.storeName,
        desc: this.obj.storeIntroduce,
        imgUrl: this.obj.storeLogo,
        link: window.location.hash,
      };
      WechatSharing(shareInfo);
    },
    toBack() {
      // console.log(this.$route.query.goods);
      if (window.history.length <= 3) {
        this.$router.replace("/");
      }
      // else if (this.$route.name === "my-order") {
      //   this.$router.push("/Home?index=3");
      // }
      else {
        this.$router.back();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.store {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: 0 auto;
  background: #f5f5f5;
}
.store_bg {
  width: 100%;
  height: 150px;
  font-size: 0;
  background-position-x: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(5px);
}
.store_top {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.store_tab {
  display: flex;
  padding: 10px 16px 4px;
  height: 50px;
  background: #fff;
  align-items: center;
  a {
    line-height: 24px;
    padding: 0 12px;
    margin-right: 22px;
    background: #fff;
    border-radius: 12px;
    color: #333;
  }
  a.router-link-active {
    color: #fff;
    background: #009a4d;
  }
}

::v-deep .van-search {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px 15px;
  z-index: 100;
  background: none;
  font-size: 14px;
  .van-search__content {
    background-color: #f2f2f2;
    border: 1px solid #009a4d;
    .van-cell {
      padding: 0;
      align-items: center;
      overflow: inherit;

      .van-field__left-icon {
        margin-right: 8px;
      }
      .van-cell__value {
        .van-field__right-icon {
          .van-button {
            position: absolute;
            top: -1px;
            right: -1px;
            padding: 0 16px;
            height: 30px;
            background: linear-gradient(180deg, #0ae377 0%, #009a4d 100%);
            border: none;
            color: #ffffff;
            z-index: 101;
          }
        }
      }
      .van-field__control {
        height: 28px;
        font-size: 12px;
        padding: 0 60px 0 4px;
      }
      .van-field__control::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #999999;
      }
      .van-field__control:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #999999;
      }
      .van-field__control::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #999999;
      }
      .van-field__control:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #999999;
      }
    }
  }
}
/deep/ svg.back {
  color: #fff;
}
/deep/.van-search {
  position: relative;
}
.store_name {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-top: 20px;
  font-size: 16px;
  color: #fff;
  img {
    width: 44px;
    height: 44px;
    padding: 2px;
    background: #fff;
    border-radius: 50%;
    margin-right: 12px;
  }
  p {
    font-size: 16px;
    font-weight: 600;
    // margin-bottom: 6px;
  }
  a {
    display: flex;
    align-items: center;
    padding: 2px 10px;
    background: #fff;
    border: 1px solid #009a4d;
    border-radius: 12px;
    color: #009a4d;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}
</style>
